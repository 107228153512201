<template>

  <div id="simple-calendar-app" >
    <vs-alert v-if="success == 'true'" color="success" title="Success" active="true">
      Votre commande a été confirmée et payée avec succès. Une fois validée, vous recevrez un email de confirmation, et vous pouvez par la suite commencer à la gérer.<br>
      Merci d'indiquer vos indisponibilités
    </vs-alert>

    <br>
    <div class="vx-card no-scroll-content">
      <vue-cal :selected-date="today"
               :disable-views="['years', 'year']"
               :events="simpleCalendarEvents"
               :on-event-click="openEditEvent"
               locale="fr"
               class="vuecal--full-height-delete">
      </vue-cal>
    </div>
    <!-- POPUP  Rendez vous-->
    <vs-popup :active.sync="activePromptEditEvent" :background-color-popup="colorx" background-color="color"
              title="Modifier événement" class="details negociateur">
      <div class="event">
        <h3 class="titreRDV">Détails événement</h3>
        <vs-input name="event-name" class="w-full" label-placeholder="Titre"
                  v-model="title" disabled=true></vs-input>
        <div class="my-4">
          <table class="detailsRDV w-full">
            <tr>
              <td class="w-6/12"><small class="date-label">Date</small><br>
                <flat-pickr :config="configdatePicker" class="w-full mr-5" v-model="start" placeholder="Date debut" />
              </td>
              <td class="w-6/12">
                <small class="date-label">Heure</small><br>
                <vue-timepicker class=" w-full" v-model="startTime" format="HH:mm" >
                </vue-timepicker>
              </td>
            </tr>
          </table>
          <small class="date-label">Durée</small><br>
          <select class="vs-select vx-col w-12/12" v-model="minutes" >
            <option value="60" selected>60 minutes</option>
            <option v-for="item in minutesOptions" :key="item.key" :value="item.key">{{item.value}}</option>
          </select>
        </div>
        <textarea
          disabled="disabled"
          label-placeholder="Description"
          type="text"
          v-model="description"
          class="w-full textarea"
          :placeholder="description"
        />

      </div>

      <div v-if="rendezVous !== null" class="ad">
        <h3 class="titreRDV">Détails rendez-vous</h3>
        <div v-if="rendezVous.ad">
          <vs-row>
            <table class="detailsRDV">
              <tr>
                <td rowspan="3" style="padding: 0"><img class="imageRdv" :src="rendezVous.ad.photo"></td>
                <td class="header">
                  {{rendezVous.ad.tradeType.name}}
                </td>
                <td class="header"><b>Réf :</b> {{rendezVous.ad.reference}}
                  <div class="float-right">{{rendezVous.ad.surface}} m<sup>2</sup></div>
                </td>
                <td class="header">{{rendezVous.ad.price | priceFormat}}</td>
              </tr>
              <tr>
                <td colspan="3"> {{rendezVous.ad.description}}
                  <div class="adLinkBlock">
                    <a :href="rendezVous.ad.adUrl" target="_blank" class="adLinkDetails">
                      <feather-icon svgClasses="w-4 h-4" icon="LinkIcon" class="mr-2"/>
                      Lien de l'annonce</a></div>
                </td>
              </tr>
            </table>
          </vs-row>
        </div>
        <p class="idAd"> Rendez vous d'identifiant <span>{{rendezVous.id}}</span> de la commande <span
          v-if="rendezVous.commande"><a :href="'commandes/'+rendezVous.commande.id" target="_blank" >{{rendezVous.commande.reference}} <feather-icon svgClasses="w-4 h-4" icon="ExternalLinkIcon" class="mr-2"/></a></span>
        </p>
        <vs-row class="corpsRDV mt-6">
          <vs-col v-if="rendezVous.ad" vs-justify="center" vs-align="center" vs-w="3" style="background: #ffbd02;">

              <feather-icon svgClasses="w-4 h-4" icon="UserIcon" class="mr-2"/>
              <b>Vendeur </b><br>
              {{rendezVous.ad.nomVendeur}}  <br>
              <br>
              <feather-icon svgClasses="w-4 h-4" icon="MapIcon" class="mr-2"/>
              {{rendezVous.ad.adresseBien}}
              <br>
              {{rendezVous.ad.postalCode}}, {{rendezVous.ad.adCity}}
              <br>
              <feather-icon svgClasses="w-4 h-4" icon="PhoneCallIcon" class="mr-2"/>
              {{rendezVous.ad.mobileNumber}}

          </vs-col>
          <vs-col vs-justify="center" vs-align="center" vs-w="3">
               <span class="statut"><feather-icon svgClasses="w-4 h-4" icon="ApertureIcon"
                                                  class="mr-2"/><b>Statut : </b>
                 <div class="withOverlay">
                  <feather-icon v-if="this.oldStatut == 'Annulé'"  svgClasses="w-4 h-4" :icon="iconAnnulation" class="mr-2 " />
                <div class="alt-overlay">
                <div class="text">{{ labelAnnulation }}</div>
            </div>
                 </div>

          <select class="vs-select vx-col w-12/12 mb-5" v-model="rendezVous.statut" :disabled="this.oldStatut == 'Réalisé' || this.oldStatut == 'Annulé' || this.oldStatut == 'En attente d\'annulation' ? true : false">
            <option v-for="item in optionsStatut" :key="item.key" :value="item.key" :disabled="item.disabled">{{item.value}}</option>
          </select>
           </span>
             <br>
               <feather-icon svgClasses="w-4 h-4" icon="CalendarIcon" class="mr-2 " />
            <b>Date : </b>{{ start}}

          </vs-col>
          <vs-col vs-justify="center" vs-align="center" vs-w="3">
              <textarea
                type="text"
                v-model="rendezVous.commentaire"
                class="w-full textarea"
                placeholder="Commentaires"
              />
          </vs-col>
          <vs-col vs-justify="center" vs-align="center" vs-w="3">
            <feather-icon svgClasses="w-4 h-4" icon="FileTextIcon" class="mr-2"/>
            <b>Numéro du mandat :</b>
            <vs-input
              class="w-full mb-2"
              :icon="mandatIcon"
              icon-pack="feather"
              :disabled="this.rendezVous.statut !== 'Réalisé'"
              :class="mandatValide"
              @change="validerLongeurMandat"
              type="text"
              v-model="rendezVous.mandat"
            />
            <vs-button  color="primary" type="border" icon-pack="feather" icon="icon-search" @click="validerMandat">Valider n° mandat</vs-button>
          </vs-col>
        </vs-row>

        <vs-row class="mt-20">
          <vs-col vs-justify="center" vs-align="center" vs-w="12">
            <div v-if="rendezVous.teleconseiller">
              <b>Téleconseiller : </b> {{rendezVous.teleconseiller.firstName}} {{rendezVous.teleconseiller.lastName}}
              <br>Dernière mise à jour <b>: {{dateMiseAjour}} </b>Par <b>{{ whoUpdated}}</b>
            </div>
          </vs-col>
        </vs-row>
        <vs-row  v-if=" this.oldStatut !== this.rendezVous.statut && this.rendezVous.statut == 'Annulé'" class="mt-5 mb-10">
          <vs-col vs-justify="center" vs-align="center" vs-w="12">
            <vs-alert color="warning" title="Alerte" active="true">
              Vous devez passer une demande d'annulation.
            </vs-alert>
          </vs-col>
        </vs-row>
        <vs-row  v-if=" this.oldStatut !== this.rendezVous.statut && this.rendezVous.statut == 'Réalisé'" class="mt-5">
          <vs-col vs-justify="center" vs-align="center" vs-w="12">
            <vs-alert color="success" title="Alerte" active="true">
              Si vous avez eu un mandat, n'oubliez pas de mettre son numéro.
            </vs-alert>
          </vs-col>
        </vs-row>

      <!-- Demande d'annulation du rendez-vous -->
        <vs-row class="corpsRDV annulation" v-if=" this.oldStatut !== this.rendezVous.statut && this.rendezVous.statut == 'Annulé'" >
          <h3 class="titreRDV">Demande d'annualtion du rendez-vous</h3>

        <p>J'effectue cette demande d'annulation du RDV avec le vendeur <b>{{this.rendezVous.ad.nomVendeur}} </b> pour le motif suivant : </p>

        <v-select :options="optionsMotif"
                  placeholder="Motif d'annulation *"
                  v-model="motifAnnulation"
                  class="mt-5 w-full"/>
          <br>
        <vs-checkbox class="mt-5 w-full" name="confirmation" v-model="confirmation"
                  >Avez-vous confirmé le rdv au vendeur?
        </vs-checkbox><br>
          <vs-checkbox class=" w-full" name="confirmation" v-model="vendeurRencontre">Avez-vous rencontré le vendeur?
          </vs-checkbox><br>
          <textarea
            label-placeholder="Commentaires"
            type="text"
            v-model="commentaireAnnulation"
            class="w-full textarea mt-5 "
            placeholder="Précision *"
          />

        </vs-row>
        <div class="mt-6 float-right">
          <vs-button class="mr-3 mb-2 " @click="editEvent()" :disabled="(this.oldStatut == 'Réalisé' || this.oldStatut == 'Annulé' || this.oldStatut == 'En attente d\'annulation') && this.oldMandat == rendezVous.mandat ">Mettre à jour
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar';
import moduleCalendar from '@/store/calendar/moduleCalendar.js';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { fr } from 'vuejs-datepicker/src/locale';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import 'vue-cal/dist/drag-and-drop.js';
import 'vue-cal/dist/i18n/fr.js';
import moment from 'moment';
import imageRdv from '@/assets/images/pages/historique.png';
import vSelect from 'vue-select';
import VueTimepicker from 'vue2-timepicker';
import { French } from 'flatpickr/dist/l10n/fr.js';
import axios from '../../axios.js';
import filters from '../../filters/filters.js';

require('vue2-timepicker/dist/VueTimepicker.css');
require('vue-simple-calendar/static/css/default.css');
require('@/assets/css/custom.css');

export default {
  components: {
    VueTimepicker,
    VueCal,
    'v-select': vSelect,
    CalendarView,
    CalendarViewHeader,
    filters,
    flatPickr,
  },
  filters: {
    priceFormat(value) {
      if (value) {
        const val = (value / 1).toFixed(2).replace('.', ' ');
        return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} € `;
      }
    },
  },
  props: ['success'],
  data() {
    return {
      activeLoading: false,
      dateMiseAjour: '',
      whoUpdated: '',
      optionsStatut: [
        { key: 'A venir', value: 'A venir', disabled: true },
        { key: 'A statuer', value: 'A statuer', disabled: true },
        { key: 'En attente d\'annulation', value: 'En attente d\'annulation', disabled: true },
        { key: 'Réalisé', value: 'Réalisé' },
        { key: 'Annulé', value: 'Annulé' },
      ],
      optionsMotif: ['Vendeur absent ', 'Vendeur annule le rdv ', 'Le bien est vendu ', 'Mandat exclusif irrévoccalbe ', 'Mandat signé par un confrère pp', 'Autre'],
      today: new Date(),
      selectedEvent: {},
      showDate: new Date(),
      configdatePicker: {
        enableTime: false,
        dateFormat: 'Y-m-d',
        locale: French,
      },
      configTimePicker: {
        enableTime: true,
        enableDate: false,
        dateFormat: 'H:i',
      },
      startTime: '',
      colorx: '#ffffff',
      disabledFrom: false,
      id: 0,
      title: '',
      titre: '',
      start: '',
      end: '',
      oldStatut: '',
      oldMandat: '',
      label: '',
      description: '',
      labelLocal: 'none',
      langFr: fr,
      url: '',
      calendarView: 'month',
      imageRdv,
      rendezVous: null,
      desc: '',
      activePromptAddEvent: false,
      activePromptEditEvent: false,
      nomVendeur: '',
      motifAnnulation: '',
      confirmation: false,
      vendeurRencontre: false,
      commentaireAnnulation: '',
      iconAnnulation: '',
      labelAnnulation: '',
      minutes: 0,
      minutesOptions: [
        {
          key: 75,
          value: '1 heure et 15 minutes',
        },
        {
          key: 90,
          value: '1 heure 30 minutes',
        },
        {
          key: 105,
          value: '1 heure et 45 minutes',
        },
        {
          key: 120,
          value: '2 heures',
        },
        {
          key: 135,
          value: '2 heures et 15 minutes',
        },
        {
          key: 150,
          value: '2 heures 30 minutes',
        },
        {
          key: 165,
          value: '2 heures et 45 minutes',
        },
        {
          key: 180,
          value: '3 heures',
        },
        {
          key: 195,
          value: '3 heures et 15 minutes',
        },
        {
          key: 210,
          value: '3 heures 30 minutes',
        },
        {
          key: 225,
          value: '3 heures et 45 minutes',
        },
        {
          key: 240,
          value: '4 heures',
        }],
      mandatIcon: 'icon-help',
      mandatValide: '',
    };
  },
  computed: {
    simpleCalendarEvents() {
      return this.$store.state.calendar.events;
    },

    disabledDatesTo() {
      return { to: new Date(this.start) };
    },
    disabledDatesFrom() {
      return { from: new Date(this.end) };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  methods: {
    validForm() {
      return this.title !== '' && this.start !== '' && this.end !== '' && Date.parse(this.end) - Date.parse(this.start) >= 0;
    },
    validFormAnnulation() {
      return this.motifAnnulation !== '' && this.commentaireAnnulation !== '';
    },

    validerLongeurMandat() {
      if (this.rendezVous.mandat.length !== 6) {
        this.rendezVous.mandat = this.oldMandat;
        this.$toasted.show('Le numéro de mandat doit contenir 6 caractéres!').goAway(1500);
      }
    },
    validerMandat() {
      const numMandat = this.rendezVous.mandat;
      const { trigramme } = this.rendezVous.negociateur;
      axios.get(`/api/mandat/${this.$store.state.AppActiveUser.user.id}/${numMandat + trigramme}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((response) => {
          this.$toasted.show(response.data).goAway(1500);
          this.mandatValide = response.status === 200 ? 'mandat-valide' : 'mandat-invalide';
          this.mandatIcon = response.status === 200 ? 'icon-check-circle' : 'icon-x-circle';
        });
    },
    updateMonth(val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val);
    },
    clearFields() {
      this.title = this.end = this.url = '';
      this.id = 0;
      this.labelLocal = 'none';
    },
    openEditEvent(event) {
      this.activeLoading = true;
      this.$vs.loading({
        type: 'point',
      });
      // const e = this.$store.dispatch('calendar/getEvent',  { "idNegociateur": this.$store.state.AppActiveUser.user.id, "idEvent":event.id} )
      axios.get(`/api/evenement/getAppointment/${this.$store.state.AppActiveUser.user.id}/${event.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((response) => {
          const event = {
            id: response.data.id,
            start: moment(response.data.start).format('YYYY-MM-DD'),
            startTime: moment(response.data.start).format('HH:mm'),
            end: moment(response.data.end).format('YYYY-MM-DD HH:mm'),
            title: response.data.title,
            rendezVous: response.data.rendezVous,
            description: response.data.desc,
            invId: response.data.invId,
            // negociateur : values.negociateur.trigramme+" "+ values.negociateur.firstName+" "+ values.negociateur.lastName
          };
          if (response.data.rendezVous !== null) {
            axios.get(`/api/rendez_vouses/${response.data.rendezVous.id}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              },
            })
              .then((responseRdvA) => {
                this.rendezVous = responseRdvA.data;
                this.oldStatut = responseRdvA.data.statut;
                this.oldMandat = responseRdvA.data.mandat;
              });
            axios.get(`/api/historique_rdvs?rendezVous=${response.data.rendezVous.id}&order[dateAction]=desc`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              },
            })
              .then((responseRdv) => {
                if (responseRdv.data.length !== 0) {
                  this.whoUpdated = responseRdv.data[0].who;
                  this.dateMiseAjour = moment(responseRdv.data[0].dateAction).format('YYYY-MM-DD HH:mm:ss ');
                } else {
                  this.whoUpdated = '';
                  this.dateMiseAjour = '';
                }
              });
            this.checkAnnulation(event.rendezVous.id);
          }
          this.id = event.id;
          this.title = event.title;
          this.start = event.start;
          this.end = event.end;
          this.startTime = event.startTime;
          this.description = event.description;
          this.invId = event.invId;
          this.activePromptEditEvent = true;
          this.activeLoading = false;
          const duration = moment
            .duration(moment(event.end, 'YYYY/MM/DD HH:mm')
              .diff(moment(response.data.start, 'YYYY/MM/DD HH:mm'))).asMinutes();
          this.minutes = duration;
          this.$vs.loading.close();
        });
    },
    editEvent() {
      if ((!!(this.oldStatut == 'Réalisé' || this.oldStatut == 'Annulé')) && this.oldMandat == this.rendezVous.mandat) {
        this.$vs.notify({
          title: 'Avertissement',
          text: 'Vous ne pouvez pas modifier ce rendez-vous.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          time: 5000,
        });
        return;
      }
      const numMandat = this.rendezVous.mandat;
      const { trigramme } = this.rendezVous.negociateur;
      // Mettre numéro de mandat
      axios.get(`/api/mandat/${this.$store.state.AppActiveUser.user.id}/${numMandat + trigramme}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((response) => {
          let mandat = '';
          const obj = {
            user: this.$store.state.AppActiveUser.user.id,
            id: this.id,
            statut: this.rendezVous.statut,
            dateDebut: `${this.start} ${this.startTime}`,
            dateFin: moment(`${this.start} ${this.startTime}`).add(1, 'hour').format('YYYY-MM-DD HH:mm:ss'),
            commentaire: this.rendezVous.commentaire,
            idRdv: this.rendezVous.id,
          };
          if (this.rendezVous.statut == 'Réalisé' && this.rendezVous.mandat !== null) {
            if (response.status === 200) {
              mandat = this.rendezVous.mandat;
              obj.mandat = mandat;
            } else {
              this.$toasted.show('Le mandat ne sera pas enregistré, il est invalide!').goAway(1500);
            }
          }
          if (this.oldStatut !== this.rendezVous.statut && this.rendezVous.statut == 'Annulé') {
            if (this.validFormAnnulation()) {
              const objAnnulation = {
                dateDemande: moment().format('YYYY-MM-DD HH:mm'),
                negociateur: `/api/mandataries/${this.$store.state.AppActiveUser.user.id}`,
                teleconseiller: `/api/employees/${this.rendezVous.teleconseiller.id}`,
                rendezVous: `/api/rendez_vouses/${this.rendezVous.id}`,
                commande: `/api/commandes/${this.rendezVous.commande.id}`,
                commentaire: this.commentaireAnnulation,
                rdvConfirme: this.confirmation,
                vendeurRencontre: this.vendeurRencontre,
                statut: 'A traiter',
              };
              obj.statut = 'En attente d\'annulation';
              axios.post('/api/demande_annulations', objAnnulation, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
              });
            } else {
              this.$vs.notify({
                title: 'Avertissement',
                text: 'Veuillez remplir la demande d\'annulation',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'warning',
                time: 5000,
              });
              return;
            }
          }
          const objEvent = {
            user: this.$store.state.AppActiveUser.user.id,
            id: this.id,
            start: `${this.start} ${this.startTime}`,
            end: moment(`${this.start} ${this.startTime}`).add(parseInt(this.minutes), 'minutes').format('YYYY-MM-DD HH:mm:ss'),
            invId: this.invId,
            description: this.description,
            title: this.title,
          };
          this.$store.dispatch('calendar/editEvent', objEvent);
          this.$store.dispatch('calendar/editAppointment', obj);
          this.activePromptEditEvent = false;
          this.nomVendeur = '',
          this.commentaireAnnulation = '',
          this.confirmation = false;
          this.$vs.notify({
            title: 'Succès',
            text: 'Le rendez-vous a été mis à jour!',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success',
            time: 5000,
          });
        });
    },
    removeEvent() {
      this.$store.dispatch('calendar/removeEvent', this.id);
      this.activePromptEditEvent = false;
    },
    checkAnnulation(rdvId) {
      axios.get(`/api/demande_annulations?rendezVous=${rdvId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((response) => {
          if (response.data !== null && response.data.length > 0) {
            if (response.data[0].statut == 'A traiter') {
              this.iconAnnulation = 'AlertCircleIcon';
              this.labelAnnulation = 'Demande en cours de traitement';
            } else if (response.data[0].statut == 'Refusé') {
              this.iconAnnulation = 'XOctagonIcon';
              this.labelAnnulation = 'Demande refusée, pas de remboursement';
            } else {
              this.iconAnnulation = 'CheckCircleIcon';
              this.labelAnnulation = 'Demande acceptée';
            }
          }
        });
    },
  },
  created() {
    this.$store.registerModule('calendar', moduleCalendar);
    this.$store.dispatch('calendar/fetchEvents', {
      idNegociateur: this.$store.state.AppActiveUser.user.id,
      startDate: '1-1-2020',
      endDate: '1-1-2050',
    });
  },
  beforeDestroy() {
    this.$store.unregisterModule('calendar');
  },
};
</script>
